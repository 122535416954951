body {
  background-color: white;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #8dc342;
}

@media (min-width: 576px) {
  .App-logo {
    height: 10vmin;
  }
}